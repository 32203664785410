/* BREAK POINT Helper */
.nav {
  width: 100vw;
  height: 100px;
  display: none;
  padding: 0 25px 0 100px;
  background-color: rgba(32, 33, 44, 0.9);
  z-index: 100;
  position: absolute;
  top: -100px;
}
.nav.show {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
}
.nav #nav-logo {
  width: 100px;
}
.nav button,
.nav .btn-primary {
  font-size: 14px;
  width: 230px;
  height: 54px;
}
.nav .btn-tertiary {
  background-color: transparent;
  width: auto;
  margin: 0 20px;
  color: #279aff;
  -webkit-transform: none;
          transform: none;
}
@media only screen and (max-width: 1399px) {
  .nav {
    padding: 0 20px 0 80px;
  }
}
@media only screen and (max-width: 765px) {
  .nav {
    padding: 0 15px 0 30px;
  }
  .nav button,
  .nav .btn-primary,
  .nav .btn-tertiary {
    font-size: 12px;
    width: auto;
    height: 40px;
  }
  .nav .btn-primary,
  .nav .btn-tertiary {
    padding: 10px 3px;
    margin: 0 10px;
  }
}
@media only screen and (max-width: 540px) {
  .nav {
    padding: 0 15px 0 30px;
    height: 60px;
  }
  .nav #nav-logo {
    width: 80px;
  }
  .nav button,
  .nav .btn-primary,
  .nav .btn-tertiary {
    font-size: 9px;
    width: auto;
    height: 30px;
    padding: 7px;
  }
  .nav .btn-tertiary {
    margin: 0 10px;
    padding: 0;
  }
}
@media only screen and (max-width: 479px) {
  .nav {
    padding: 0 7px 0 12px;
  }
  .nav .btn-primary,
  .nav .btn-tertiary {
    margin: 0;
  }
  .nav .btn-tertiary {
    padding: 0;
  }
}
#below-the-fold {
  padding: 140px 0 0 0;
  margin: 0;
  /* LARGE */
  /*MEDIUM LARGE*/
  /* MEDIUM */
  /*SMALL-MEDIUM 540*/
  /* LARGE */
  /* MEDIUM LARGE */
  /* SMALL-MEDIUM 540 */
  /* SMALL 320 */
  /* Text row with 3 columns */
  /* LARGE */
  /* MEDIUM LARGE */
  /* SMALL-MEDIUM */
  /* SMALL */
  /* LARGE */
  /* LARGE */
  /* MEDIUM */
  /* SMALL */
  /* SMALL-MEDIUM */
  /* Heading Line Breaks */
}
#below-the-fold h2 {
  font-family: Avenir-Medium;
  font-size: 50px;
  line-height: 1.2;
  margin: 0;
  text-align: left;
  font-weight: 900;
}
#below-the-fold h2.text-center {
  margin: auto;
  text-align: center;
}
#below-the-fold h3 {
  font-family: Avenir-Medium;
  font-size: 36px;
  line-height: 1.28;
  margin: 15px 0;
  padding-right: 50px;
}
#below-the-fold p,
#below-the-fold h4 {
  font-size: 18px;
  line-height: 1.67;
  font-weight: normal;
  color: #d3d4de;
  text-align: left;
  margin: 20px 0;
}
#below-the-fold p.secondary,
#below-the-fold h4.secondary {
  max-width: 40vw;
}
#below-the-fold p.text-center,
#below-the-fold h4.text-center {
  margin: 20px auto;
  text-align: center;
}
#below-the-fold .text-left {
  text-align: left;
}
@media only screen and (max-width: 1399px) {
  #below-the-fold {
    padding: 80px 0 0 0;
  }
  #below-the-fold h2 {
    font-size: 40px;
    line-height: 1.25;
  }
  #below-the-fold h3 {
    font-size: 24px;
    line-height: 1.5;
    padding-right: 100px;
  }
  #below-the-fold p,
  #below-the-fold h4 {
    font-size: 16px;
    line-height: 1.5;
    margin: 20px 0;
  }
  #below-the-fold p.secondary,
  #below-the-fold h4.secondary {
    max-width: 535px;
  }
  #below-the-fold p.text-center,
  #below-the-fold h4.text-center {
    margin: 20px auto;
    max-width: 535px;
  }
  #below-the-fold p {
    max-width: 220px;
  }
}
@media only screen and (max-width: 1023px) {
  #below-the-fold {
    padding: 80px 0 0 0;
  }
  #below-the-fold h3 {
    padding: 0;
    font-size: 36px;
    line-height: 1.28;
    margin: 15px 0;
  }
  #below-the-fold p,
  #below-the-fold h4 {
    font-size: 16px;
    margin: 15px 0;
  }
  #below-the-fold p.secondary,
  #below-the-fold h4.secondary {
    max-width: 535px;
  }
  #below-the-fold p.text-center,
  #below-the-fold h4.text-center {
    margin: 15px auto;
    max-width: 535px;
  }
  #below-the-fold p {
    font-size: 18px;
    max-width: 100%;
  }
}
@media only screen and (max-width: 765px) {
  #below-the-fold {
    padding: 60px 0 0 0;
  }
  #below-the-fold h4.text-center {
    max-width: 430px;
  }
}
@media only screen and (max-width: 540px) {
  #below-the-fold [data-aos=fade-up] {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
  }
  #below-the-fold [data-aos=fade-up].aos-animate {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  #below-the-fold h2 {
    font-size: 22px;
    line-height: 1.45;
    text-align: center;
  }
  #below-the-fold h3 {
    font-size: 18px;
    line-height: 1.44;
    margin: 5px 0;
  }
  #below-the-fold p,
  #below-the-fold h4 {
    font-size: 14px;
    line-height: 1.42;
    margin: 10px auto;
    max-width: 90%;
    text-align: center;
  }
  #below-the-fold p.secondary,
  #below-the-fold h4.secondary {
    margin: 10px auto;
    max-width: 80vw;
  }
  #below-the-fold p.text-center,
  #below-the-fold h4.text-center {
    margin: 10px auto;
  }
}
#below-the-fold .caption {
  margin: 0 auto;
  padding: 0 110px 110px;
  position: relative;
  overflow: hidden;
  /* Layout Styles */
}
#below-the-fold .caption .target {
  position: absolute;
  top: -100px;
  width: 1px;
  height: 1px;
  pointer-events: none;
}
#below-the-fold .caption .wrapper {
  position: relative;
  z-index: 1;
  max-width: 1400px;
  margin: 0 auto;
}
#below-the-fold .caption .foreground-image {
  width: 100%;
}
#below-the-fold .caption .foreground-image img {
  display: block;
  width: 100%;
}
#below-the-fold .caption .background-image {
  position: absolute;
  z-index: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
#below-the-fold .caption .background-image img {
  display: block;
  width: 100%;
}
#below-the-fold .caption.grid1 .foreground-image {
  padding-top: 12px;
}
#below-the-fold .caption.sidealign .wrapper {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#below-the-fold .caption.sidealign .meta {
  padding: 0 5% 0 0;
  width: 50%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
#below-the-fold .caption.sidealign .foreground-image {
  width: 50%;
}
#below-the-fold .caption.sidealign:nth-of-type(even) .wrapper {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
#below-the-fold .caption.sidealign:nth-of-type(even) .meta {
  padding: 0 0 0 5%;
}
#below-the-fold .caption.grid3 .grid,
#below-the-fold .caption.grid4 .grid,
#below-the-fold .caption.grid5 .grid,
#below-the-fold .caption.row3 .grid {
  margin: 0 -12px;
}
#below-the-fold .caption.multilayer {
  margin-left: 0;
  margin-right: 0;
  padding: 0;
}
#below-the-fold .caption.multilayer .foreground-image,
#below-the-fold .caption.multilayer .background-image {
  margin-top: 20px;
}
#below-the-fold .caption.multilayer .wrapper {
  max-width: none;
}
@media only screen and (max-width: 1399px) {
  #below-the-fold .caption {
    margin: 0 auto;
    padding: 55px 80px;
    /* Layout Styles */
  }
  #below-the-fold .caption .oneidButton {
    margin-bottom: 55px;
  }
  #below-the-fold .caption .meta {
    width: auto;
    padding: 0 5%;
    display: block;
  }
  #below-the-fold .caption.sidealign {
    text-align: center;
  }
  #below-the-fold .caption.sidealign .meta {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    padding: 0 5%;
    width: auto;
  }
  #below-the-fold .caption.sidealign .foreground-image {
    width: auto;
  }
  #below-the-fold .caption.sidealign:nth-of-type(even) .meta {
    padding: 0;
  }
  #below-the-fold .caption.multilayer .foreground-image,
  #below-the-fold .caption.multilayer .background-image {
    margin-top: 40px;
  }
}
@media only screen and (max-width: 1023px) {
  #below-the-fold .caption {
    margin: 0 auto;
    padding: 40px 80px;
    /* Layout Styles */
  }
  #below-the-fold .caption .oneidButton {
    margin-bottom: 40px;
  }
  #below-the-fold .caption .meta {
    width: auto;
    padding: 0 5%;
    display: block;
  }
  #below-the-fold .caption.sidealign {
    text-align: center;
  }
  #below-the-fold .caption.sidealign .meta {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    padding: 0 5%;
    width: auto;
  }
  #below-the-fold .caption.sidealign .foreground-image {
    width: auto;
  }
  #below-the-fold .caption.sidealign:nth-of-type(even) .meta {
    padding: 0;
  }
}
@media only screen and (max-width: 540px) {
  #below-the-fold .caption {
    padding: 40px 60px;
  }
  #below-the-fold .caption.grid3 .grid {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 479px) {
  #below-the-fold .caption {
    margin: 0 auto;
    padding: 25px;
  }
  #below-the-fold .caption .oneidButton {
    margin-bottom: 25px;
  }
  #below-the-fold .caption.multilayer .foreground-image,
  #below-the-fold .caption.multilayer .background-image {
    margin-top: 20px;
  }
}
#below-the-fold .grid .grid_item {
  display: inline-block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 12px;
  vertical-align: top;
}
#below-the-fold .grid .grid_item .wrapper {
  display: block;
  border: 1px solid transparent;
}
#below-the-fold .grid .grid_item a {
  color: #fff;
  display: block;
  padding: 0;
  text-decoration: none;
  -webkit-transition: -webkit-transform 150ms;
  transition: -webkit-transform 150ms;
  transition: transform 150ms;
  transition: transform 150ms, -webkit-transform 150ms;
}
#below-the-fold .grid .grid_item img {
  display: inline-block;
  vertical-align: bottom;
  width: 100%;
}
#below-the-fold .grid .grid_item a:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
  border: 1px solid #333437;
}
#below-the-fold .grid3 {
  text-align: left;
}
#below-the-fold .grid3 .grid_item {
  width: 33.33%;
}
#below-the-fold .grid4 .grid_item {
  width: 25%;
}
#below-the-fold .grid5 .grid_item {
  width: 20%;
}
#below-the-fold .row3 .grid_item {
  width: 33.333%;
}
#below-the-fold .row3 .grid_item .wrapper {
  max-width: 280px;
}
#below-the-fold .row3 .grid_item .wrapper img {
  width: 60px;
}
@media only screen and (max-width: 1399px) {
  #below-the-fold .row3 .grid_item .wrapper img {
    width: 40px;
  }
}
@media only screen and (max-width: 1023px) {
  #below-the-fold .grid3 .grid_item,
  #below-the-fold .grid4 .grid_item {
    width: 50%;
  }
  #below-the-fold .grid3 .grid_item.hide,
  #below-the-fold .grid4 .grid_item.hide {
    display: none;
  }
  #below-the-fold .grid3 .wrapper,
  #below-the-fold .grid4 .wrapper {
    text-align: left;
  }
  #below-the-fold .grid5 .grid_item {
    width: 33.33%;
  }
  #below-the-fold .row3 .text-left .wrapper {
    text-align: center;
    margin: auto;
    display: block;
  }
  #below-the-fold .row3 .text-left .wrapper p {
    text-align: center;
  }
  #below-the-fold .row3 .grid_item {
    width: 100%;
  }
  #below-the-fold .row3 .grid_item .wrapper {
    max-width: none;
  }
  #below-the-fold .row3 .grid_item .wrapper img {
    width: 60px;
  }
}
@media only screen and (max-width: 540px) {
  #below-the-fold .grid .grid_item {
    padding: 6px;
  }
  #below-the-fold .grid3 .grid_item,
  #below-the-fold .grid4 .grid_item {
    width: 50%;
  }
  #below-the-fold .grid3 .wrapper,
  #below-the-fold .grid4 .wrapper {
    margin: auto;
    display: block;
  }
  #below-the-fold .row3 .grid_item {
    padding: 15px 5px;
  }
  #below-the-fold .row3 .grid_item .wrapper img {
    width: 40px;
  }
  #below-the-fold .text-left {
    text-align: center;
    margin: auto;
  }
}
@media only screen and (max-width: 479px) {
  #below-the-fold .grid .grid_item {
    padding: 5px;
  }
}
#below-the-fold #countdown-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  /*align-items: center;*/
  margin: 30px auto;
}
#below-the-fold #countdown-bottom .counter-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 2px #78728a solid;
  padding-top: 7px;
  width: 164px;
  height: 164px;
  margin: 10px;
}
#below-the-fold #countdown-bottom .counter-box .unit-title {
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 3px;
  font-weight: 500;
}
#below-the-fold #countdown-bottom .counter-box .unit {
  font-size: 30px;
  letter-spacing: 2.3px;
  font-weight: 900;
}
@media only screen and (max-width: 1399px) {
  #below-the-fold #countdown-bottom {
    margin: 35px auto;
  }
  #below-the-fold #countdown-bottom .counter-box {
    width: 136px;
    height: 136px;
    margin: 10px;
  }
}
@media only screen and (max-width: 765px) {
  #below-the-fold #countdown-bottom {
    margin: 35px auto;
  }
  #below-the-fold #countdown-bottom .counter-box {
    width: 86px;
    height: 86px;
    margin: 8px;
  }
  #below-the-fold #countdown-bottom .counter-box .unit-title {
    font-size: 10px;
    letter-spacing: 2.5px;
  }
  #below-the-fold #countdown-bottom .counter-box .unit {
    font-size: 26px;
    letter-spacing: 2px;
  }
}
@media only screen and (max-width: 540px) {
  #below-the-fold #countdown-bottom {
    margin: 20px auto;
  }
  #below-the-fold #countdown-bottom .counter-box {
    border: 1px #78728a solid;
    padding-top: 2px;
    width: 62px;
    height: 65px;
    margin: 2px;
  }
  #below-the-fold #countdown-bottom .counter-box .unit-title {
    font-size: 9px;
    letter-spacing: 2.3px;
  }
  #below-the-fold #countdown-bottom .counter-box .unit {
    font-size: 18px;
    letter-spacing: 1.4px;
  }
}
@media only screen and (max-width: 479px) {
  #below-the-fold #countdown-bottom {
    margin: 20px auto;
  }
}
#below-the-fold button {
  width: 300px;
  font-size: 18px;
}
@media only screen and (max-width: 540px) {
  #below-the-fold button {
    width: 200px;
    font-size: 14px;
    height: calc(35px + 1.1vw);
  }
}
#below-the-fold h1 span,
#below-the-fold h2 span,
#below-the-fold h3 span,
#below-the-fold h4 span,
#below-the-fold h5 span,
#below-the-fold h6 span {
  display: block;
}
@media only screen and (max-width: 1399px) {
  #below-the-fold .multilayer h2 span {
    display: inline;
  }
}
@media only screen and (max-width: 1023px) {
  #below-the-fold h3 span {
    display: inline;
  }
}
@media only screen and (max-width: 765px) {
  #below-the-fold .multilayer h2 span {
    display: block;
  }
}
@media only screen and (max-width: 540px) {
  #below-the-fold h2 span {
    display: inline;
  }
  #below-the-fold h2.favorites span {
    display: block;
  }
}
@media only screen and (max-width: 479px) {
  #below-the-fold h2 span {
    display: block;
  }
}
@font-face {
  font-family: 'Avenir-Light';
  src: url(../fonts/Avenir-Light-07.woff) format('woff'), url(../fonts/Avenir-Light-07.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Avenir-Medium';
  src: url(../fonts/Avenir-Medium-09.woff) format('woff'), url(../fonts/Avenir-Medium-09.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Avenir-Roman';
  src: url(../fonts/Avenir-Roman-12.woff) format('woff'), url(../fonts/Avenir-Roman-12.ttf) format('truetype');
  font-weight: normal;
}
* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
html,
body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}
body {
  background-color: #040714;
  background-image: url(../images/bg-large-v4.png);
  background-size: 100% auto;
  background-position: center top;
  background-repeat: no-repeat;
  color: #ffffff;
  font-family: Avenir-Light, Arial, Helvetica, sans-serif;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  max-width: 100vw;
  overflow-x: hidden;
  min-height: 100vh;
}
img {
  max-width: 100%;
  -ms-interpolation-mode: bicubic;
}
img[src*=".svg"] {
  width: 100%;
}
#main {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 15vh 6vw 180px;
  min-height: 100vh;
  height: 100vh;
}
#main.cms-driven {
  padding: 10vh 6vw 100px;
}
#unavailable {
  width: 406px;
  height: 92px;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  margin: auto;
  font-family: Avenir-Light, Arial, Helvetica, sans-serif;
  font-size: 33px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #f9f9f9;
}
#header.logo {
  max-width: 440px;
  min-width: 220px;
  margin: 0 auto;
}
#header.logo img {
  display: block;
  width: 100%;
}
.non-branded {
  font-family: Avenir-Light, Arial, Helvetica, sans-serif;
  font-size: 50px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: center;
  color: #f9f9f9;
  text-transform: none;
}
.non-branded span {
  font-family: Avenir-Medium, Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  text-align: center;
  white-space: nowrap;
}
button {
  background-color: #279aff;
  border-radius: 4px;
  border: none;
  color: #f9f9f9;
  cursor: pointer;
  font-family: inherit;
  font-size: 22px;
  font-stretch: normal;
  font-style: normal;
  height: calc(48px + 1.1vw);
  letter-spacing: 0.5px;
  line-height: normal;
  padding: 0 10px;
  text-align: center;
  -webkit-transition: -webkit-transform 0.1s;
  transition: -webkit-transform 0.1s;
  transition: transform 0.1s;
  transition: transform 0.1s, -webkit-transform 0.1s;
  -webkit-transform: scale(0.98, 0.98);
          transform: scale(0.98, 0.98);
  max-width: 400px;
  font-family: Avenir-Roman, Arial, Helvetica, sans-serif;
}
button:hover {
  -webkit-transform: translateZ(0) scale(1, 1);
          transform: translateZ(0) scale(1, 1);
}
button:active {
  -webkit-transform: scale(1);
          transform: scale(1);
}
button:focus {
  outline: none;
}
button:focus:not(.focus-visible) {
  outline: none;
}
button.focus-visible {
  outline: 1px white solid;
}
a:focus:not(.focus-visible) {
  outline: none;
}
a.focus-visible {
  outline: 1px white solid;
}
p.fine-text {
  font-size: 12px;
  letter-spacing: 0.86px;
  line-height: 1.56;
  font-family: Avenir-Roman, Arial, Helvetica, sans-serif;
}
@media screen and (max-width: 542px) {
  p.fine-text {
    font-size: 9px;
    max-width: 70%;
    margin: 7px auto;
  }
}
@media screen and (max-width: 375px) {
  p.fine-text {
    letter-spacing: 0.64px;
  }
}
.btn-primary {
  display: inline-block;
  cursor: pointer;
  border-radius: 4px;
  text-decoration: none;
  text-align: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  vertical-align: middle;
  font-size: 16px;
  padding: 14px 36px;
  color: #fff;
  background-color: #279aff;
  border: 2px solid transparent;
  -webkit-transition: -webkit-transform 0.1s;
  transition: -webkit-transform 0.1s;
  transition: transform 0.1s;
  transition: transform 0.1s, -webkit-transform 0.1s;
  -webkit-transform: scale(0.98, 0.98);
          transform: scale(0.98, 0.98);
  min-width: 249px;
  margin: 0 5px;
  font-family: Avenir-Roman, Arial, Helvetica, sans-serif;
}
@media screen and (max-width: 766px) {
  .btn-primary {
    min-width: 120px;
  }
}
@media screen and (max-width: 542px) {
  .btn-primary {
    padding: 10px;
    font-size: 14px;
  }
}
.btn-tertiary {
  font-family: Avenir-Roman, Arial, Helvetica, sans-serif;
  color: #fff;
  background-color: transparent;
  border: 2px solid transparent;
  padding: 14px 20px;
  text-decoration: none;
}
/* BUTTON HOVER STATES */
.btn-primary:hover {
  -webkit-transform: translateZ(0) scale(1, 1);
          transform: translateZ(0) scale(1, 1);
}
.btn-tertiary:hover {
  text-decoration: underline;
}
/* BUTTON ACTIVE STATES */
.btn-primary:active,
.btn-tertiary:active {
  opacity: 0.8;
}
.no-oneID {
  text-transform: uppercase;
  font-size: 45px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.89;
  letter-spacing: 19px;
  text-align: center;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 8vw;
}
#franchises {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  max-width: 1100px;
  margin: 3vw auto;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
#franchises .row {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  width: 20%;
}
#franchises .franchise {
  width: 100%;
  max-width: 228px;
  padding: 0;
  margin: auto;
}
#franchises.cms-driven {
  max-width: 850px;
  margin: 6vh auto 0;
}
#franchises.cms-driven .franchise {
  max-width: 130px;
  padding: 0;
  margin: auto;
}
.asterisk {
  padding: 6px;
  height: 4px;
  opacity: 0.8;
  font-family: Avenir-Roman, Arial, Helvetica, sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.89;
  letter-spacing: 0.5px;
  color: rgba(255, 255, 255, 0.8);
}
#footer {
  color: rgba(255, 255, 255, 0.65);
  padding: 15px 10px;
  font-size: 11px;
  line-height: 12px;
  position: absolute;
  bottom: 0;
  left: 5%;
  right: 5%;
  width: 90%;
}
#footer.has-below-the-fold {
  position: relative;
  background-color: #040714;
}
#footer-logo {
  width: 60px;
}
.footer-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: center;
          justify-content: center;
  -ms-flex-pack: center;
  list-style-type: none;
  margin: 0 auto;
  padding: 0;
  min-width: 0;
}
.footer-link,
.footer-copy {
  color: #9b9b9b;
  display: block;
  text-decoration: none;
  padding: 10px 30px;
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
}
.footer-link:hover,
.footer-link:focus {
  color: #ffffffcc;
}
.footer-link:active {
  color: #ffffff99;
}
.uppercase {
  text-transform: uppercase;
}
.bold {
  font-weight: 900;
}
#main.cms-driven {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 5vw 6vw 100px;
}
#main #header.branded.logo,
#main.cms-driven #header.branded.logo {
  width: 320px;
}
#main.cms-driven #content button {
  min-width: 300px;
  font-size: 18px;
  height: calc(40px + 1.1vw);
}
h1 {
  font-family: Avenir-Medium;
  font-weight: 900;
  letter-spacing: 0.8px;
  font-size: 28px;
  margin: 0.5vw 0 1vw 0;
}
h3 {
  font-size: 40px;
}
#countdown-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 4vh;
}
#countdown-top .counter-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 2px #78728a solid;
  width: 86px;
  height: 86px;
  margin: 8px;
  padding-top: 9px;
}
#countdown-top .counter-box .unit-title {
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 2.5px;
}
#countdown-top .counter-box .unit {
  font-size: 26px;
  letter-spacing: 2px;
}
#hint {
  position: absolute;
  bottom: 3vh;
  right: 0;
  left: 0;
  cursor: pointer;
}
#hint a {
  padding-top: 10px;
  color: rgba(255, 255, 255, 0.65);
  text-decoration: none;
  font-size: 10px;
  letter-spacing: 2.5px;
  -webkit-transition: -webkit-transform 0.2s ease;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
  text-transform: uppercase;
}
#hint a:hover {
  color: white;
}
#hint a:hover g {
  opacity: 1;
}
#hint svg {
  width: 40px;
  display: block;
  margin: 20px auto 10px;
}
/*EU Portability*/
#eu-login {
  position: absolute;
  right: 71px;
  top: 20px;
  letter-spacing: 1.5px;
  line-height: 1.6;
  font-size: 15px;
  vertical-align: middle;
  color: #cacaca;
}
#eu-login a {
  color: #cacaca;
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
}
#eu-login a:hover {
  text-decoration: none;
  color: white;
}
#eu-login a:active {
  color: white;
}
@media screen and (max-width: 1024px) {
  #eu-login {
    right: 58px;
  }
}
@media screen and (max-width: 766px) {
  #eu-login {
    right: 55px;
  }
}
@media screen and (max-width: 480px) {
  #eu-login {
    padding: 0 7px;
    right: 0;
    left: 0;
  }
}
/* responsive stuff */
@media (min-width: 1441px) {
  #franchises {
    max-width: 1000px;
  }
}
@media (min-width: 1600px) {
  #franchises {
    max-width: 1200px;
  }
}
@media (min-width: 2500px) {
  #franchises {
    max-width: 50vw;
  }
}
@media (max-width: 1024px) {
  #franchises {
    height: 200px;
  }
  #franchises .row {
    width: 33%;
    margin-bottom: 2vw;
  }
  #franchises .franchise {
    max-width: 185px;
  }
  #franchises.cms-driven {
    height: 100px;
    margin: 3vh auto 0;
  }
  #franchises.cms-driven .row {
    width: 20%;
    margin-bottom: 2vw;
  }
  #franchises.cms-driven .franchise {
    max-width: 130px;
    padding: 0;
    margin: auto;
  }
  h1 {
    margin: 1vw 0 1vw 0;
  }
}
@media screen and (max-width: 766px) {
  body {
    background-image: url(../images/bg-small-v4.png);
  }
  #unavailable {
    font-size: 26px;
  }
  .footer-link,
  .footer-copy {
    padding: 10px 25px;
  }
  #franchises {
    height: 300px;
    max-width: 400px;
    margin-right: auto;
    margin-left: auto;
    margin: 5vw auto;
  }
  #franchises .row {
    width: 50%;
  }
  #franchises.cms-driven {
    height: 90px;
    margin: 3vh auto 0;
  }
  #franchises.cms-driven .row {
    width: 20%;
    margin-bottom: 2vw;
  }
  #franchises.cms-driven .franchise {
    max-width: 104px;
    padding: 0;
    margin: auto;
  }
  h1 {
    font-size: 28px;
  }
  #countdown-top {
    font-size: 26px;
    margin-bottom: 1em;
  }
}
@media screen and (orientation: landscape) and (max-width: 640px) {
  #header.logo {
    width: 30vw;
  }
  #franchises {
    height: 250px;
    max-width: 350px;
  }
}
@media screen and (max-width: 640px) {
  #franchises.cms-driven {
    height: 75px;
    margin: 3vh auto 0;
  }
  #franchises.cms-driven .franchise {
    max-width: 84px;
  }
  #main #header.branded.logo,
  #main.cms-driven #header.branded.logo {
    width: 225px;
  }
}
@media screen and (max-width: 542px) {
  html {
    font-size: 100%;
  }
  body {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    min-width: 320px;
  }
  #header {
    padding-top: 10vw;
  }
  .non-branded {
    font-size: 24px;
  }
  .non-branded span {
    font-size: 55px;
    text-transform: none;
    display: block;
  }
  .no-oneID {
    font-size: 35px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: 15px;
  }
  #franchises {
    height: 250px;
  }
  #franchises.cms-driven {
    padding: 6vh 0;
    height: 75px;
    margin: 0 auto 0;
  }
  #franchises.cms-driven .franchise {
    max-width: 72px;
  }
  h1 {
    font-size: 16px;
    letter-spacing: normal;
  }
  #countdown-top {
    margin-top: 0.8em;
  }
  #countdown-top .counter-box {
    border: 1px #78728a solid;
    width: 56px;
    height: 56px;
    margin: 2px;
    padding-top: 5px;
  }
  #countdown-top .counter-box .unit-title {
    text-transform: uppercase;
    font-size: 9px;
    letter-spacing: 2.3px;
  }
  #countdown-top .counter-box .unit {
    font-size: 18px;
    letter-spacing: 1.4px;
  }
  #main.cms-driven #content button {
    min-width: 200px;
    font-size: 14px;
    height: calc(35px + 1.1vw);
  }
  #hint a {
    font-size: 9px;
    letter-spacing: 2.3px;
  }
  #hint svg {
    width: 20px;
    margin: 15px auto 5px;
  }
  #main #header.branded.logo,
  #main.cms-driven #header.branded.logo {
    width: 188px;
  }
}
@media screen and (max-width: 542px) {
  #footer {
    left: 20%;
    right: 20%;
    width: 60%;
  }
  .footer-copy,
  .footer-link {
    padding: 5px 10px;
  }
}
@media screen and (max-width: 430px) {
  #footer {
    left: 10%;
    right: 10%;
    width: 80%;
  }
  #countdown-top {
    margin-top: 0.5em;
  }
  #franchises.cms-driven {
    padding: 6vh 0;
    height: 175px;
    margin: 0 auto 0;
  }
  #franchises.cms-driven .row {
    width: 33%;
    margin-bottom: 5px;
  }
  #franchises.cms-driven .franchise {
    max-width: 68px;
  }
  #main #header.branded.logo,
  #main.cms-driven #header.branded.logo {
    width: 180px;
  }
}
@media screen and (orientation: portrait) and (max-height: 812px) {
  #main {
    height: auto;
    min-height: 0;
  }
  #main.has-below-the-fold {
    padding: 5vw 6vw;
  }
  #footer {
    padding: 25px 10px;
    position: relative;
  }
  #hint {
    position: relative;
    bottom: 0;
  }
}
@media screen and (orientation: landscape) and (max-height: 700px) {
  #footer {
    position: relative;
  }
  #main {
    height: auto;
  }
  #main.has-below-the-fold {
    min-height: 750px;
  }
}
@media screen and (max-height: 800px) {
  #footer {
    position: relative;
  }
}
@media screen and (orientation: landscape) and (max-width: 825px) {
  .footer-link,
  .footer-copy {
    padding: 10px 30px;
  }
}
/* Non countdown-brand image layouts */
#main.cms-driven:not(.countdown-hero) {
  height: 100vh;
  min-height: 900px;
  padding: 15vh 6vw 10vh;
}
@media screen and (max-width: 1024px) {
  #main.cms-driven:not(.countdown-hero) {
    min-height: 768px;
  }
}
@media screen and (max-width: 542px) {
  #main.cms-driven:not(.countdown-hero) {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    height: auto;
    min-height: 500px;
  }
}
#main.cms-driven:not(.countdown-hero) #content {
  margin-bottom: 30px;
}
#main.cms-driven:not(.countdown-hero) #content h1 {
  margin: 0 auto 3%;
  max-width: 500px;
  line-height: 1.5;
}
@media screen and (max-width: 542px) {
  #main.cms-driven:not(.countdown-hero) #content h1 {
    margin: 0 auto 30px;
  }
}
#main.cms-driven:not(.countdown-hero) #franchises.cms-driven {
  margin-top: 5%;
  height: auto;
  padding: 0;
}
@media screen and (max-width: 542px) {
  #main.cms-driven:not(.countdown-hero) #franchises.cms-driven {
    margin-top: 25px;
  }
}
#main.cms-driven:not(.countdown-hero) #franchises.cms-driven img {
  width: 80%;
  max-width: 700px;
  min-width: 300px;
}
/* US fudge */
.en-US #main.cms-driven #header.logo {
  margin-top: 5%;
}
/*Bounce Animation for Hint Arrow */
.bounce {
  -webkit-animation: bounce 1.75s infinite;
  animation: bounce 1.75s infinite;
}
@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-7px);
            transform: translateY(-7px);
  }
  60% {
    -webkit-transform: translateY(-3.5px);
            transform: translateY(-3.5px);
  }
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-7px);
            transform: translateY(-7px);
  }
  60% {
    -webkit-transform: translateY(-3.5px);
            transform: translateY(-3.5px);
  }
}
/*One ID lightbox override */
div#disneyid-wrapper {
  position: fixed !important;
}
